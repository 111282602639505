<template>
  <article class="notification-card" :class="{
      'new': !notification.read,
      'updating' : reading == notification.id
    }"
    @click.stop="
              notification.text.includes('producto:') ||
              notification.text.includes('pedido:') ||
              notification.text.includes('Promocion:') ||
              notification.text.includes('Incidencia:') ||
              notification.text.includes('Incidencia cerrada:') ||
              notification.text.includes('devolución:') ||
              notification.text.includes('Promocion:') ||
              notification.text.includes('devuelto:')
              ? routeTo(notification) :
              notification.text.includes('sku:') ? routeToSku(notification) : 
              !notification.read ? markLikeReaded(notification.id) : ''
            "  
  >
      <div class="card-viewed">
          <div class="card-circle-indicator rounded-circle" :class="{
              'viewed': notification.read
          }"></div>
      </div>
      <div class="card-description">
          <div class="card__header">
              <span class="card-type">{{setupTitleForType(notification.type)}}</span>
              <span class="card-date">{{notification.creationDate.split('T')[0]}}</span>
          </div>
          <div class="card-summary"
          >
              <p class="card-info">
                  <span>{{notification.text}}</span>
              </p>
              <div class="readed" v-if="false">
                  <b-form-checkbox
                  name="check-button"
                  switch
                  v-if="!notification.read"
                  class="card-checkbox"
                  @change="markLikeReaded(notification.id)"
                  :checked="reading == notification.id"
                  :disabled="reading != '' && reading != notification.id"
                  >
                  </b-form-checkbox>
              </div>
          </div>
      </div>
  </article>
</template>

<script>
  import { ProductServiceMixim } from '@/mixims/ProductServiceMixim.js'
  export default {
    name: 'NotificationsTimeCard',
    mixins: [ProductServiceMixim],
    data () {
      return {
      }
    },
    props: ['notification', 'reading'],
    methods: {
      markLikeReaded (id) {
        this.$emit('markRead', id)
      },
      setupTitleForType (value) {
        switch (value) {
          case 'LOW_SKU_INVENTARY':
            return this.$t('Inventario de sku bajo')
          case 'COMMERCIAL_CONDITION_COMMISSION':
            return this.$t('Comercial')
          case 'CREATE_NEW_CONTROVERSY':
            return this.$t('Controversia')
          case 'CREATE_NEW_INCIDENCE':
            return this.$t('Incidencia')
          case 'CHANGE_ORDER_STATE_RETURN':
            return this.$t('Devolución')
          case 'CHANGE_ORDER_STATE_PARTIAL_RETURN':
            return this.$t('Devolución parcial')
        }
      },
      routeTo (reference) {
        let route = ''
        if (reference.text.indexOf('pedido:') !== -1) route = `/orders/orderDetail/${reference.reference}`
        if (reference.text.indexOf('producto:') !== -1) route = `/catalog/details/${reference.reference}`
        if (reference.text.indexOf('Incidencia:') !== -1) route = `/incidents/list?ref=${reference.reference}`
        if (reference.text.indexOf('Incidencia cerrada:') !== -1) route = `/incidents/list?ref=${reference.reference}`
        if (reference.text.indexOf('devolución:') !== -1) route = `/orders/orderDetail/${reference.reference}`
        if (reference.text.indexOf('devuelto:') !== -1) route = `/orders/orderDetail/${reference.reference}`
        if (reference.text.indexOf('Promocion:') !== -1) {
          this.routeToPromo(reference)
          return
        }
        if (!reference.read) this.markLikeReaded(reference.id)
        this.$router.push({path: route}).catch(() => {})
        this.$emit('hideNotify')
      },
      routeToSku (reference) {
        if (reference.text.indexOf('sku:') !== -1) {
          let filter = {
            sku: {desde: null, hasta: null},
            upc: {desde: null, hasta: null},
            fecha: {desde: null, hasta: null},
            category: {id: null, text: null},
            fromQuantity: null,
            toQuantity: null,
            account: null,
            text: reference.reference
          }
          let tableFilterInfo = {currentPage: 1, perPage: 2}
          this.getProducts(tableFilterInfo, filter).then(
            (response) => {
              if (response.body && response.body[0] && response.body[0].id) {
                if (!reference.read) this.markLikeReaded(reference.id)
                this.$router.push({path: `/catalog/details/${response.body[0].id}`, query: { sku: reference.reference }}).catch(() => {})
                this.$emit('hideNotify')
              }
            },
            (error) => {
              console.log(error)
              return []
            }
          )
        }
      },
      routeToPromo (reference) {
        if (!reference.read) this.markLikeReaded(reference.id)
        this.$router.push({path: `/catalog/promotions`, query: { promo: reference.reference }}).catch(() => {})
        this.$emit('hideNotify')
      }
    }
  }
</script>

<style lang="scss">
  @import './_notifiVars.scss';
  @import 'scss/_bootstrap-variables.scss';
  .notification-card{
      cursor: pointer;
      transition: 0.3s all;
      &.new{
          border-left: 2px solid rgba($bag-notification-color, 0.5);
          &:not(.updating){
              &:hover{
                  background-color: lighten($primary-color, 35) !important;
              }
          }
      }
      &:not(.new){
          &:hover{
              background-color: $header-border-bottom-color !important;
          }
      }
      &.updating{
          background-color: $header-border-bottom-color !important;
          .card-viewed{
              .rounded-circle{
                  background-color: darken($header-border-bottom-color, 50) !important;
              }
          }
          &:hover{
              cursor: wait;
          }
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom:1px solid $header-border-bottom-color;
      padding: 10px 15px;
      .card-viewed{
          margin-right: 15px;
          .card-circle-indicator {
              width: 8px;
              height: 8px;
              &:not(.viewed){
                  background-color: $bag-notification-color;
              }
              &.viewed{
                  border: 1px solid darken($header-border-bottom-color , 10); // #7a7a7a; 
              }
          }
      }
      .card-description{
          display: flex;
          flex-wrap: wrap;
          line-height: 1.5;
          width: 90%;
          .card__header{
              font-size: 12px;
              font-weight: 600;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding:0px !important;
              .card-type{
                  color: $notification-card-title-color;
              }
              .card-date{
                  font-size: 9.5px;
                  color: lighten($notification-card-title-color, 20);
                  font-weight: 200;
              }
          }
          .card-summary{
              display: flex;
              font-size: 12px;
              margin-top: 6px;
              align-items: center;
              justify-content: space-between;
              p.card-info{
                  &:hover {
                    text-decoration: underline;
                  }
                  font-weight: 200;
                  margin-bottom: 0px !important;
                  line-height: 1.2;
                  min-width:80%;
              }
              div.readed{
                  margin-left: 8px;
                  text-decoration: underline;
                  .card-checkbox{
                      min-height: 0.5rem !important;
                      padding: 5px;
                      &.custom-control{
                          min-height: 0.5rem !important;
                      }
                      /* .custom-control-input:checked ~ .custom-control-indicator{
                      } */
                      .custom-control-indicator{
                          top: -0.20rem !important;
                          height: 0.8rem !important;
                          width: 0.8rem !important;
                          border-radius: 3px;
                      }
                  }
              }
          }
          .card__header,
          .card-summary{
              width:100%;
          }
      }
  }
</style>